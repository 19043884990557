import React from "react";
import { Col, Row } from "react-bootstrap";
import { ThankyouPageContainer } from "./ThankyouPage.style";
import cad_logo from "../../Assests/cad_logo.png";
import thankyouImg from "../../Assests/thankyou.png";
import thankyouFooter from "../../Assests/thankyouFooter.svg";


import { RegistrationFrom } from "../RegistrationFrom/RegistrationFrom";
import { useLocation } from "react-router-dom";
export const ThankyouPage = () => {
  const location = useLocation();
  return (
    <ThankyouPageContainer>
      <Row>
        <Col className='d-flex justify-content-center align-items-center' md={6} xs={6}>
          <div className='left__container'>
            <div className='logo'>
              <img src={cad_logo} className='img-fluid' />
            </div>
            {location.pathname.includes("r/") ? (
              <RegistrationFrom />
            ) : (
              <div className='border__left'>
                <div className='boder__space'>
                  {" "}
                  <div className='footer__text without__reg'>
                    <img src={thankyouFooter} className='img-fluid' />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col md={6} xs={6}>
          <img src={thankyouImg} className='img-fluid' />
        </Col>
      </Row>
    </ThankyouPageContainer>
  );
};
