import React from "react";
import { TextAreaQuestionContainer } from "./TextAreaQuestion.style";

export const TextAreaQuestion = ({ textAreaLabel1, onChange, value, name }) => {
  return (
    <TextAreaQuestionContainer>
      {" "}
      <p className='mb-0 Nexa__Regular'>{textAreaLabel1}</p>
      <textarea name={name} rows='3' value={value} onChange={onChange}></textarea>
    </TextAreaQuestionContainer>
  );
};
