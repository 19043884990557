import styled from "styled-components";

export const RadioQuestionContainer = styled.div`
  position: relative;
  margin-bottom: 1.4em;
  .error__massage__radio {
    padding-left: 6.88em;
    font-size: 0.8em;

    color: red;
  }
`;
export const QuestionLabel = styled.div`
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(37, 33, 34, 1) 38.25%,
    rgba(44, 40, 41, 1) 52.03%,
    rgba(55, 51, 52, 1) 61.85%,
    rgba(72, 68, 69, 1) 69.79%,
    rgba(93, 90, 91, 1) 76.6%,
    rgba(120, 118, 118, 1) 82.62%,
    rgba(152, 150, 151, 1) 88.07%,
    rgba(189, 188, 188, 1) 93.08%,
    rgba(229, 229, 229, 1) 97.54%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(37, 33, 34, 1) 38.25%,
    rgba(44, 40, 41, 1) 52.03%,
    rgba(55, 51, 52, 1) 61.85%,
    rgba(72, 68, 69, 1) 69.79%,
    rgba(93, 90, 91, 1) 76.6%,
    rgba(120, 118, 118, 1) 82.62%,
    rgba(152, 150, 151, 1) 88.07%,
    rgba(189, 188, 188, 1) 93.08%,
    rgba(229, 229, 229, 1) 97.54%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    100% 50%,
    color-stop(0, rgba(35, 31, 32, 1)),
    color-stop(0.3825, rgba(37, 33, 34, 1)),
    color-stop(0.5203, rgba(44, 40, 41, 1)),
    color-stop(0.6185, rgba(55, 51, 52, 1)),
    color-stop(0.6979, rgba(72, 68, 69, 1)),
    color-stop(0.766, rgba(93, 90, 91, 1)),
    color-stop(0.8262, rgba(120, 118, 118, 1)),
    color-stop(0.8807, rgba(152, 150, 151, 1)),
    color-stop(0.9308, rgba(189, 188, 188, 1)),
    color-stop(0.9754, rgba(229, 229, 229, 1)),
    color-stop(1, rgba(255, 255, 255, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(37, 33, 34, 1) 38.25%,
    rgba(44, 40, 41, 1) 52.03%,
    rgba(55, 51, 52, 1) 61.85%,
    rgba(72, 68, 69, 1) 69.79%,
    rgba(93, 90, 91, 1) 76.6%,
    rgba(120, 118, 118, 1) 82.62%,
    rgba(152, 150, 151, 1) 88.07%,
    rgba(189, 188, 188, 1) 93.08%,
    rgba(229, 229, 229, 1) 97.54%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(37, 33, 34, 1) 38.25%,
    rgba(44, 40, 41, 1) 52.03%,
    rgba(55, 51, 52, 1) 61.85%,
    rgba(72, 68, 69, 1) 69.79%,
    rgba(93, 90, 91, 1) 76.6%,
    rgba(120, 118, 118, 1) 82.62%,
    rgba(152, 150, 151, 1) 88.07%,
    rgba(189, 188, 188, 1) 93.08%,
    rgba(229, 229, 229, 1) 97.54%,
    rgba(255, 255, 255, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#231F20', endColorstr='#FFFFFF' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(35, 31, 32, 1) 0%,
    rgba(37, 33, 34, 1) 38.25%,
    rgba(44, 40, 41, 1) 52.03%,
    rgba(55, 51, 52, 1) 61.85%,
    rgba(72, 68, 69, 1) 69.79%,
    rgba(93, 90, 91, 1) 76.6%,
    rgba(120, 118, 118, 1) 82.62%,
    rgba(152, 150, 151, 1) 88.07%,
    rgba(189, 188, 188, 1) 93.08%,
    rgba(229, 229, 229, 1) 97.54%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#231F20',endColorstr='#FFFFFF' , GradientType=1);
  p {
    font-size: 1.105em;
    line-height: 1.4;
    margin-bottom: 0;
    padding-top: 0.3em;
    padding-left: 5.625em;
    font-family: "NexaBookItalic";
  }
`;
export const QuestionTextNum = styled.div`
  background: #3a3731;
  width: 100%;
  /* height: 5.0625em; */
  padding: 0.6em 0;
  .number {
    width: 5em;
    p {
      font-size: 3.0625em;
      line-height: 1;
      color: rgba(255, 255, 255, 0.6);
      border-right: 0.05em solid #fff;
      text-align: end;
      padding-right: 0.38em;
    }
  }
  .question {
    width: 100%;
    p {
      color: #fff;
      font-size: 1.3625em;
      padding-left: 0.88em;
      line-height: 1;
      width: 99%;
      @media screen and (max-width: 900px) {
        font-size: 1.2625em;
      }
      span {
        &.colored__word {
          color: #c1b49a;
        }
      }
    }
  }
`;

export const QuestionInputs = styled.div`
  margin-left: 5em;
  padding-left: 0.9em;
  background: #bbbdbf;
  padding-top: 0.3em;
  padding-bottom: 0.3em;

  .right__space {
    padding-right: 8.4375em;
  }
  .form-check-label {
    cursor: pointer;

    font-size: 1em;
    line-height: 1;
    padding-top: 0.51em;
    padding-left: 0.4em;
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 15%);
    cursor: pointer;
  }
  .form-check-input:checked[type="radio"] {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23380f10'/%3e%3c/svg%3e") !important;
  }
  .form-check-input:checked[type="checkbox"] {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23380f10' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  .form-check-input {
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #000000;
    -webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  .form-check-input:checked {
    background-color: transparent;
    border: 2px solid #000000;
    cursor: pointer;
  }
`;
