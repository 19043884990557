import styled from "styled-components";
import overviewBg from "../../Assests/overViewBg.png";
export const OverViewWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-image: url(${overviewBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .overview__container {
    .layer_two,
    .layer_one {
      top: 0;
    }
    .start__btn {
      width: 11.0625em;
      left: 3.9375em;
      bottom: 13em;
      cursor: pointer;
      @media screen and (max-width: 1700px) {
        width: 9.0625em;
        left: 3.9375em;
        bottom: 11em;
        cursor: pointer;
      }
      @media screen and (max-width: 1100px) {
        width: 8.0625em;
        left: 2.9375em;
        bottom: 9em;
        cursor: pointer;
      }
      @media screen and (max-width: 600px) {
        bottom: 6em;
      }
    }
  }
`;
