import styled from "styled-components";
import { device } from "../GlobalMediaStyle/Media.style";
export const MainWrapper = styled.section`
  font-size: 16px;
  overflow-x: hidden;
  min-height: 100vh;
  height: 100%;
  .Book__Antiqua {
    font-family: "BookAntiqua";
  }
  .Nexa__Light {
    font-family: "NexaLight";
  }
  .NexaBook__Italic {
    font-family: "NexaBookItalic";
  }
  .Nexa__Regular {
    font-family: "NexaRegular";
  }
  .Nexa__Bold {
    font-family: "NexaBold";
  }

  @media (orientation: portrait) {
    display: none;
  }
  @media (orientation: landscape) {
    display: flex;
    flex-direction: column;
  }
  @media ${device.screen1680} {
    font-size: 15px;
    .right__space {
      padding-right: 7.4375em !important;
    }
  }
  @media ${device.screen1480} {
    font-size: 14px;
    .right__space {
      padding-right: 7.4375em !important;
    }
  }
  @media ${device.screen1280} {
    font-size: 13px;
    .right__space {
      padding-right: 6.4375em !important;
    }
  }
  @media ${device.screen1050} {
    font-size: 11.5px;
  }
  @media screen and (min-width: 900px) {
    .meduim__screen {
      display: none;
    }
    .large__screen {
      display: block;
    }
  }
  @media screen and (max-width: 1030px) {
    font-size: 11px;
    .right__space {
      padding-right: 5.4375em !important;
    }
  }
  @media screen and (max-width: 899px) {
    .meduim__screen {
      display: block;
    }
    .large__screen {
      display: none;
    }
  }
  @media ${device.screen900} {
    .right__space {
      padding-right: 5.4375em !important;
    }
    font-size: 10px;
  }
  @media ${device.screen800} {
    font-size: 9.5px;
  }
  @media ${device.screen680} {
    .right__space {
      padding-right: 4.4375em !important;
    }
    font-size: 8.5px;
  }
  @media screen and (max-width: 500px) {
    .right__space {
      padding-right: 2.4375em !important;
    }
    font-size: 7.5px;
  }
  /* @media ${device.screen428} {
    font-size: 9px;
  }
  @media ${device.screen390} {
    font-size: 8px;
  }
  @media ${device.screen340} {
    font-size: 8px;
  } */
`;
export const PortraitMessageContainer = styled.div`
  background-color: #411113;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .portrait__massage {
    img {
      width: 28em;

      /* @media screen and (max-width: 1560px) {
        width: 25em;
      } */
      /* @media screen and (max-width: 1280px) {
        width: 22em;
      } */
      /* @media screen and (max-width: 1000px) {
        width: 18em;
      }
      @media screen and (max-width: 900px) {
        width: 15em;
      } */
      @media screen and (max-width: 500px) {
        width: 18em;
      }
      @media screen and (max-width: 350px) {
        width: 14em;
      }
    }
  }
  @media (orientation: portrait) {
    display: block;
  }
  @media (orientation: landscape) {
    display: none;
  }
`;
