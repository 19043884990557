import React from 'react'
import { PortraitMessageContainer } from '../../MainStyle/MainStyle.style'
import portraitMassage from '../../Assests/portrait-body.png'
export const PortraitMessage = () => {

  return (
    <PortraitMessageContainer>
         <div className="portrait__massage d-flex justify-content-center align-items-center" id="portraitPage">
   
            <img src={portraitMassage} alt="portrait massage" className="img-fluid"/>
  
    </div>
    </PortraitMessageContainer>
  )
}
