/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { FormContainer } from "./RegistrationForm.style";
import thankyouFooter from "../../Assests/thankyouFooter.svg";

import submit_btn from "../../Assests/submit-btn.png";
import { merge } from "lodash";
import axios from "axios";
export const RegistrationFrom = () => {
  const [isSubmit, setSubmit] = useState(false);
  // const allowDomains = ["caduceuslane.com", "electa-studios.com"];
  // const defaultValues = {
  //   select: "",
  //   input: "",
  //   email: "",
  // };
  const {
    // form,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    // control,
  } = useForm();
  const onSubmit = async (data) => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const newData = merge(data, localData);
    // console.log(domain);
    // let emailValue = data.email;
    const res2 = await axios.post("https://internal-back.nexus-ecosystem.com/api/AnswersSurvey/08dae29d-4949-4dff-8640-8b48d0168ffc", { Form: JSON.stringify(newData) }, {});
    localStorage.setItem("data", JSON.stringify({}));
    setSubmit(true);
    console.log(setSubmit);
    document.querySelector(".submit__btn").style.display = "none";
    setTimeout(() => {
      document.querySelector(".errorMassage3").style.display = "none";
    }, 80);
    // document.querySelector(".errorMassage3").style.display = "none";

    return true;
  };

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-5]+\.)+[a-zA-Z]{2,}))$/;
  const dnsRecord = useCallback(
    async (e) => {
      let emailValue = e.target.value;
      const domain = emailValue.split("@")[1];
      if (emailRegex.test(emailValue)) {
        document.querySelector(".error__email").style.display = "none";
        try {
          const res = await fetch(`https://dns.google/resolve?name=${domain}`);
          let json = await res.json();
          console.log(json.Status);
          if (json.Status !== 0) {
            document.querySelector(".errorMassage3").style.display = "block";
            setSubmit(false);
          } else if (json.Status == 0) {
            return true;
          }
        } catch (err) {
          if (!err.statusCode) {
            // it error
            document.querySelector(".errorMassage3").style.display = "block";
            setSubmit(true);
          }
          console.log(err.statusCode);
          console.log("it_problem");
          document.querySelector(".errorMassage3").style.display = "block";
          setSubmit(false);
        }
      } else if (!emailRegex.test(emailValue) && emailValue != "") {
        document.querySelector(".error__email").style.display = "block";
        setSubmit(false);
      }
    },
    [watch]
  );
  return (
    <FormContainer className='d-flex flex-column Nexa__Light'>
      <form className='registration__form Nexa__Light d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>
        <div className='border__left'>
          <div className='boder__space'>
            <input
              placeholder='Name'
              name='firstName'
              className='inputs__style'
              type='text'
              onKeyPress={(e) => {
                if (!e.key.match(/[a-zA-Z ]*$/)) {
                  e.preventDefault();
                }
              }}
              {...register("firstName", {
                required: true,
                maxLength: 150,
                minLength: 2,
                pattern: {
                  value: /[a-zA-Z ]*$/,
                },
              })}
              aria-invalid={errors.firstName ? "true" : "false"}
              disabled={isSubmit}
            />
            {errors.firstName?.type === "required" && (
              <p className='error__massage' role='alert'>
                This field is required
              </p>
            )}
            {errors.firstName?.type === "minLength" && (
              <p className='error__massage' role='alert'>
                The minimum characters in this filed is 2 characters.
              </p>
            )}
            {errors.firstName?.type === "maxLength" && (
              <p className='error__massage' role='alert'>
                The maximum characters in this filed is 150 characters.
              </p>
            )}
            {errors.firstName?.type === "pattern" && (
              <p className='error__massage' role='alert'>
                please insert text only
              </p>
            )}
            <input
              placeholder='Title'
              className='inputs__style'
              onKeyPress={(e) => {
                if (!e.key.match(/[a-zA-Z ]*$/)) {
                  e.preventDefault();
                }
              }}
              {...register("Title", {
                required: true,
                maxLength: 150,
                minLength: 2,
                pattern: {
                  value: /[a-zA-Z ]*$/,
                },
              })}
              aria-invalid={errors.Title ? "true" : "false"}
              disabled={isSubmit}
              type='text'
            />
            {errors.Title?.type === "required" && (
              <p className='error__massage' role='alert'>
                This field is required
              </p>
            )}
            {errors.Title?.type === "minLength" && (
              <p className='error__massage' role='alert'>
                The minimum characters in this filed is 2 characters.
              </p>
            )}
            {errors.Title?.type === "maxLength" && (
              <p className='error__massage' role='alert'>
                The maximum characters in this filed is 150 characters.
              </p>
            )}
            {errors.Title?.type === "pattern" && (
              <p className='error__massage' role='alert'>
                please insert text only
              </p>
            )}

            {/* <Controller
              control={control}
              name='email'
              render={({ field }) => ( */}
            <input
              placeholder='Email'
              name='email'
              className='inputs__style'
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                },
              })}
              onChange={dnsRecord}
              aria-invalid={errors.email ? "true" : "false"}
              disabled={isSubmit}
              type='email'
            />
            {/* )} */}
            {/* /> */}
            {/* {errors.email && (
              <span className='error__massage' role='alert'>
                {errors.email.message}
              </span>
            )} */}
            {errors.email?.type === "required" && (
              <p className='error__massage' role='alert'>
                This field is required
              </p>
            )}
            <p className='error__massage error__email' role='alert'>
              {" "}
              Invalid Email format. (Email format: Example@domain.com)
            </p>
            {/* {errors.email?.type === "pattern" && (
              <p className='error__massage error__email' role='alert'>
                Invalid Email format. (Email format: Example@domain.com)
              </p>
            )} */}
            {/* <span className='error__massage errorMassage2' role='alert'>
              Invalid Email format. (Email format: Example@domain.com)
            </span> */}
            <span className='error__massage errorMassage3' role='alert'>
              Email domain validation failed. Please recheck the domain. if the problem persists recheck if there any restrictions with the IT department. You can still submit if you wish to continue
              without validating the email domain.
            </span>
            {/* <span className='error__massage errorMassage4' role='alert'>
              Invalid Email format. (Email format: Example@domain.com)
            </span> */}
            <input
              type='text'
              onKeyPress={(e) => {
                if (!e.key.match(/[a-zA-Z ]*$/)) {
                  e.preventDefault();
                }
              }}
              placeholder='Franchise'
              className='inputs__style'
              {...register("Franchise", {
                required: true,
                maxLength: 150,
                minLength: 2,
                pattern: {
                  value: /[a-zA-Z ]*$/,
                },
              })}
              aria-invalid={errors.Franchise ? "true" : "false"}
              disabled={isSubmit}
            />
            {errors.Franchise?.type === "required" && (
              <p className='error__massage' role='alert'>
                This field is required
              </p>
            )}
            {errors.Franchise?.type === "minLength" && (
              <p className='error__massage' role='alert'>
                The minimum characters in this filed is 2 characters.
              </p>
            )}
            {errors.Franchise?.type === "maxLength" && (
              <p className='error__massage' role='alert'>
                The maximum characters in this filed is 150 characters.
              </p>
            )}
            {errors.Franchise?.type === "pattern" && (
              <p className='error__massage' role='alert'>
                please insert text only
              </p>
            )}
            <select {...register("Region", { required: true })} aria-invalid={errors.Region ? "true" : "false"} disabled={isSubmit}>
              <option value=''>Region</option>
              <option value='UAE'>UAE</option>

              <option value='KSA'>KSA</option>

              <option value='EGY'>EGY</option>

              <option value='Other'>Other</option>
            </select>
            {errors.Region?.type === "required" && (
              <p className='error__massage' role='alert'>
                This field is required
              </p>
            )}
            <div className='footer__text'>
              <img src={thankyouFooter} className='img-fluid' />
            </div>
          </div>
        </div>

        <div className='w-100 text-end mb-4'>
          {" "}
          <button type='submit' className='submit__btn'>
            <img src={submit_btn} className='img-fluid' />
          </button>
        </div>

        {/* <input /> */}
      </form>
    </FormContainer>
  );
};
