import React from 'react'

export const OverlayLoading = ({loading}) => {

  return (
    <div className='loading-overlay' id='loading'>
      <div className='reverse-spinner'></div>
    </div>
  );
}
