import React from 'react'
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/fonts.css';

import { PortraitMessage } from './Components/PortraitMessage/PortraitMessage';
import { OverView } from './Components/OverView/OverView';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Components/Routes/Routes';
import { QuestionsPageOne } from './Components/QuestionsPage/QuestionsPageOne';
import { ThankyouPage } from './Components/ThankyouPage/ThankyouPage';
import { OverlayLoading } from './Components/OverlayLoading/OverlayLoading';
import { useEffect, useState } from 'react';
import { store } from './Store/store';
import { Provider } from 'react-redux';
import { MainWrapper } from './MainStyle/MainStyle.style';


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    const data = localStorage.getItem('data')
    if (!data) {
      localStorage.setItem('data', JSON.stringify({}))
    }

    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        setLoading(false);
      }
    });
  }, []);
  return (
    <>
      <PortraitMessage />
      {loading ? <OverlayLoading /> : null}
      <Router>
        <Provider store={store}>
          <MainWrapper>
            <Routes />
          </MainWrapper>
        </Provider>


      </Router>

    </>
  );
}

export default App;
