import styled from "styled-components";
import QuestionsBg from "../../Assests/QuestionsBg.png";

export const QuestionsPageContainer = styled.section`
  position: relative;
  width: 100%;
  background-image: url(${QuestionsBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  .next__prev {
    /* position: relative; */
    width: 100%;
    justify-content: space-between;
    display: flex;

    padding: 1em 0;
    display: flex;
    .prev__btn {
      width: 7.0625em;
      /* position: relative; */
      margin-left: 4em;
      cursor: pointer;
    }
    .next__btn {
      width: 5.5625em;
      /* position: relative; */
      margin-right: 4em;
      bottom: 0em;
      cursor: pointer;
    }
  }
`;
