import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { QuestionInputs, QuestionLabel, QuestionTextNum, RadioQuestionContainer } from "./RadioQuestion.style";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../Store/valueSlice";
import { TextAreaQuestion } from "../TextAreaQuestion/TextAreaQuestion";

export const RadioQuestion = ({
  textAreaLabel1,
  textAreaLabel2,
  textArea,
  textArea2,
  error,
  Options,
  QuestionLabelText,
  num,
  questionText,
  labelInput,
  idInput,
  group,
  value,
  handleSelect,
  data,
  data2,
  name,
  name2,
}) => {
  useEffect(() => {
    // console.log(Options);
    console.log(textArea);
  }, []);

  return (
    <RadioQuestionContainer>
      <QuestionLabel>
        <p className='NexaBook__Italic text-white' dangerouslySetInnerHTML={{ __html: QuestionLabelText }}></p>
      </QuestionLabel>
      <QuestionTextNum className='question__num Nexa__Bold d-flex align-items-center'>
        <div className='number'>
          <p className='mb-0' dangerouslySetInnerHTML={{ __html: num }}></p>
        </div>
        <div className='question'>
          <p className='mb-0' dangerouslySetInnerHTML={{ __html: questionText }}></p>
        </div>
      </QuestionTextNum>
      <QuestionInputs>
        <div className='d-flex w-100'>
          {Options &&
            Options?.map((item, index) => (
              <Form.Check
                className='Nexa__Regular right__space d-flex align-items-center'
                name={group}
                key={index}
                label={item.labelInput}
                id={`${group}-${index}`}
                type='radio'
                value={item.labelInput}
                onChange={handleSelect}
                checked={data === item.labelInput}
              />
            ))}
          <div className='d-flex flex-column'>
            {" "}
            {textArea && textArea ? <TextAreaQuestion name={name} textAreaLabel1={textAreaLabel1} onChange={handleSelect} value={data} /> : null}
            {textArea2 && textArea2 ? <TextAreaQuestion name={name2} textAreaLabel1={textAreaLabel2} onChange={handleSelect} value={data2} /> : null}
          </div>
        </div>
      </QuestionInputs>
      <span className='error__massage__radio Nexa__Regular' id=''>
        {error && data === "" && !textArea2 && !textArea && "*Please select an answer"}
        {error && textArea2 && textArea && (data === "" || data2 === "") && "Please enter your answer above"}
        {error && textArea && !textArea2 && (data === "" || data2 === "") && "Please enter your answer above"}
      </span>
    </RadioQuestionContainer>
  );
};
