import React, { useEffect, useState } from "react";
import { RadioQuestion } from "../RadioQuestion/RadioQuestion";
import { QuestionsPageContainer } from "./QuestionsPage.style";
import { Form } from "react-bootstrap";
import { QuestionInputs } from "../RadioQuestion/RadioQuestion.style";
import { Footer } from "../Footer/Footer";
import prevBtn from "../../Assests/prev-Btn.png";
import nextBtn from "../../Assests/next-btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateState } from "../../Store/valueSlice";
import { merge } from "lodash";

export const QuestionsPageOne = ({ QuestionTitle, QuestionTitleMeduim }) => {
  const [data, setData] = useState({
    easiness: "",
    assess_usefulness: "",
    analytics: "",
    need: "",
    perceived_value:""
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SecOneQuestions = [
    {
      name: "easiness",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest in easiness and 1 to the least in easiness,",
      num: "1",
      questionText: 'How do you assess <span class="colored__word">the easiness of use</span> of the currently available virtual event platforms?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group1",
      handleSelect: (e) => setData({ ...data, easiness: e.target.value }),
    },

    {
      name: "assess_usefulness",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the most useful and 1 to the least useful,",
      num: "2",
      questionText: 'How do you assess <span class="colored__word">the perceived usefulness</span> of the currently available virtual event platforms?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group2",
      handleSelect: (e) => setData({ ...data, assess_usefulness: e.target.value }),
    },

    {
      name: "analytics",
      QuestionLabelText: "In relevance to your experience in event marketing through the currently available virtual platforms,",
      num: "3",
      questionText: "Can virtual platforms deliver customizable analytics and reporting outcomes that maximize virtual event ROI?",
      Options: [
        {
          labelInput: "Strongly Disagree",
          idInput: "stronglyDisagree",
        },
        {
          labelInput: "Disagree",
          idInput: "disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "neutral",
        },
        {
          labelInput: "Agree",
          idInput: "agree",
        },
        {
          labelInput: "Strongly Agree",
          idInput: "stronglyAgree",
        },
      ],
      group: "group3",

      handleSelect: (e) => setData({ ...data, analytics: e.target.value }),
    },
    {
      name: "need",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest need and 1 to the least need,",
      num: "4",
      questionText: 'How do you rate <span class="colored__word">your need for a virtual exhibition area for your virtual event?</span>',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group4",

      handleSelect: (e) => setData({ ...data, need: e.target.value }),
    },
    {
      name: "perceived_value",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the most valuable and 1 to the least valuable,",
      num: "5",
      questionText: 'How do you assess <span class="colored__word">the perceived value</span> of the currently available virtual event platforms from your customers’ perspective?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group5",

      handleSelect: (e) => setData({ ...data, perceived_value: e.target.value }),
    },
  ];
  // const SecOneOptions = [
  //   { labelInput: "1", idInput: "1" },
  //   { labelInput: "2", idInput: "2" },
  // ];

  const handleClick = () => {
    setError(false);
    for (const key in data) {
      const element = data[key];
      if (element === "") {
        setError(true);
        // alert("error");
        return;
      }
    }

    dispatch(updateState(data));
    const localData = JSON.parse(localStorage.getItem("data"));
    localStorage.setItem("data", JSON.stringify(merge(localData, data)));
    location.pathname.includes("r/") ? navigate("/r/eventmarketing") : navigate("/eventmarketing");
  };

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const dataClone = structuredClone(data);
    document.querySelector(".active__tab").style.background = "#ffffffa8";

    for (const key in data) {
      if (localData[key]) dataClone[key] = localData[key];
    }
    console.log(dataClone);
    setData(dataClone);
  }, []);
  return (
    <>
      {" "}
      <QuestionsPageContainer>
        <div className='sec1__question__title'>
          <div className='large__screen'>
            <img className='img-fluid' src={QuestionTitle} />
          </div>
          <div className='meduim__screen'>
            <img className='img-fluid' src={QuestionTitleMeduim} />
          </div>
          <Form>
            {" "}
            {SecOneQuestions.map((item, index) => (
              <RadioQuestion
                error={error}
                group={item.group}
                Options={item.Options}
                key={index}
                QuestionLabelText={item.QuestionLabelText}
                num={item.num}
                questionText={item.questionText}
                value={item.value}
                handleSelect={item.handleSelect}
                data={data[item.name]}
              />
            ))}
          </Form>
        </div>
        <div className='next__prev'>
          <div className='prev__btn'></div>
          <div className='next__btn'>
            <img src={nextBtn} className='img-fluid' onClick={handleClick} />
          </div>
        </div>
      </QuestionsPageContainer>
      <Footer className='w-100' />
    </>
  );
};
