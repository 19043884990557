import styled from "styled-components";
import { device } from "../../GlobalMediaStyle/Media.style";

export const ThankyouPageContainer = styled.div`
  .border__left {
    border-left: 1px solid #ffffff5b;
    /* padding-right: 1em; */
    .boder__space {
      padding-left: 1em;
      display: flex;
      flex-direction: column;
    }
  }
  /* border-left: 2px solid #ffffffc9; */
  background-color: #3f1315;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
  min-height: 100vh;
  .left__container {
    width: 41.875em;
    height: 100%;
    .logo {
      width: 13.25em;
      /* position: relative; */
      margin: 3em 0;
      /* margin-bottom: 3em; */
    }
    .footer__text {
      width: 95%;
      margin: 2em auto;
    }
    @media ${device.screen900} {
      font-size: 9px;
    }
    @media ${device.screen800} {
      font-size: 8px;
    }
    @media ${device.screen680} {
      font-size: 6.5px;
    }
    @media screen and (max-width: 500px) {
      font-size: 5px;
    }
  }
`;
