import React from "react";
import { useRoutes } from "react-router-dom";
import { OverView } from "../OverView/OverView";
import { QuestionsPageOne } from "../QuestionsPage/QuestionsPageOne";
import QuestionTitle1 from "../../Assests/sec1-1920.png";
import QuestionTitle2 from "../../Assests/sec2-1920.png";
import QuestionTitle3 from "../../Assests/sec3-1920.png";
import QuestionTitle4 from "../../Assests/sec4-1920.png";
import QuestionTitle5 from "../../Assests/sec5-1920.png";

import QuestionTitleMeduim from "../../Assests/sec1-900.png";
import QuestionTitleMeduim2 from "../../Assests/sec2-900.png";
import QuestionTitleMeduim3 from "../../Assests/sec3-900.png";
import QuestionTitleMeduim4 from "../../Assests/sec4-900.png";

import { QuestionsPageTwo } from "../QuestionsPage/QuestionsPageTwo";
import { QuestionsPageThree } from "../QuestionsPage/QuestionsPageThree";
import { QuestionsPageFour } from "../QuestionsPage/QuestionsPageFour";
import { QuestionsPageFive } from "../QuestionsPage/QuestionsPageFive";

import { ThankyouPage } from "../ThankyouPage/ThankyouPage";
import HomeWithRegist from "../../Page/HomeWithRegist/HomeWithRegist";
import HomeWithoutRegist from "../../Page/HomeWithoutRegist/HomeWithoutRegist";
export const Routes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <HomeWithoutRegist />,
      children: [
        { path: "", element: <OverView /> },
        {
          path: "technologyadvancement",
          element: <QuestionsPageOne QuestionTitle={QuestionTitle1} QuestionTitleMeduim={QuestionTitleMeduim} />,
        },

        {
          path: "eventmarketing",
          element: <QuestionsPageTwo QuestionTitle={QuestionTitle2} QuestionTitleMeduim={QuestionTitleMeduim2} />,
        },

        {
          path: "virtualevents",
          element: <QuestionsPageThree QuestionTitle={QuestionTitle3} QuestionTitleMeduim={QuestionTitleMeduim3} />,
        },
        {
          path: "understandingneeds",
          element: <QuestionsPageFour QuestionTitle={QuestionTitle4} QuestionTitleMeduim={QuestionTitleMeduim4} />,
        },
        {
          path: "plannedevents",
          element: <QuestionsPageFive QuestionTitle={QuestionTitle5} QuestionTitleMeduim={QuestionTitle5} />,
        },
        {
          path: "thankyou",
          element: <ThankyouPage />,
        },
      ],
    },
    {
      path: "/r",
      element: <HomeWithRegist />,
      children: [
        { path: "", element: <OverView /> },
        {
          path: "technologyadvancement",
          element: <QuestionsPageOne QuestionTitle={QuestionTitle1} QuestionTitleMeduim={QuestionTitleMeduim} />,
        },

        {
          path: "eventmarketing",
          element: <QuestionsPageTwo QuestionTitle={QuestionTitle2} QuestionTitleMeduim={QuestionTitleMeduim2} />,
        },

        {
          path: "virtualevents",
          element: <QuestionsPageThree QuestionTitle={QuestionTitle3} QuestionTitleMeduim={QuestionTitleMeduim3} />,
        },
        {
          path: "understandingneeds",
          element: <QuestionsPageFour QuestionTitle={QuestionTitle4} QuestionTitleMeduim={QuestionTitleMeduim4} />,
        },
        {
          path: "plannedevents",
          element: <QuestionsPageFive QuestionTitle={QuestionTitle5} QuestionTitleMeduim={QuestionTitle5} />,
        },
        {
          path: "thankyou",
          element: <ThankyouPage />,
        },
      ],
    },
  ]);
  return routes;
};
