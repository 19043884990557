import React, { useEffect, useState } from "react";
import { RadioQuestion } from "../RadioQuestion/RadioQuestion";
import { QuestionsPageContainer } from "./QuestionsPage.style";
import { Form } from "react-bootstrap";
import { QuestionInputs } from "../RadioQuestion/RadioQuestion.style";
import { Footer } from "../Footer/Footer";
import prevBtn from "../../Assests/prev-Btn.png";
import nextBtn from "../../Assests/next-btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateState } from "../../Store/valueSlice";
import { merge } from "lodash";
import axios from "axios";

export const QuestionsPageFive = ({ QuestionTitle, QuestionTitleMeduim }) => {
  const [data, setData] = useState({
    virtual_events: "",
    many_virtual: "",
    virtual_event_management: "",
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SecFiveQuestions = [
    {
      name: "virtual_events",
      QuestionLabelText: "",
      num: "1",
      questionText: 'How many virtual events have you executed in <span class="colored__word">2022</span>?',
      Options: [
        {
          labelInput: "1–5",
          idInput: "",
        },
        {
          labelInput: "5–20",
          idInput: "",
        },
        {
          labelInput: "20–50",
          idInput: "",
        },
        {
          labelInput: ">50",
          idInput: "",
        },
      ],
      group: "group22",
      handleSelect: (e) => setData({ ...data, virtual_events: e.target.value }),
    },

    {
      name: "many_virtual",
      QuestionLabelText: "",
      num: "2",
      questionText: 'How many virtual events are you planning in <span class="colored__word">2023</span>?',
      Options: [
        {
          labelInput: "1–5",
          idInput: "",
        },
        {
          labelInput: "5–20",
          idInput: "",
        },
        {
          labelInput: "20–50",
          idInput: "",
        },
        {
          labelInput: ">50",
          idInput: "",
        },
      ],
      group: "group23",
      handleSelect: (e) => setData({ ...data, many_virtual: e.target.value }),
    },

    {
      name: "virtual_event_management",
      QuestionLabelText: "Based on your own perception regarding your professional contribution in virtual event management,",
      num: "3",
      questionText: "What are the criteria for the virtual event management platform that you would be choosing for your next virtual event?",
      textArea: "true",
      handleSelect: (e) => setData({ ...data, virtual_event_management: e.target.value }),
    },
  ];

  const handleClick = async () => {
    setError(false);
    for (const key in data) {
      const element = data[key];
      if (element === "") {
        setError(true);
        return;
      }
    }
    dispatch(updateState(data));
    const localData = JSON.parse(localStorage.getItem("data"));
    localStorage.setItem("data", JSON.stringify(merge(localData, data)));

    if (!location.pathname.includes("r/")) {
      try {
        const res = await axios.post("https://internal-back.nexus-ecosystem.com/api/AnswersSurvey/08dae369-af30-4c31-8aa6-8b3052395105", { Form: JSON.stringify(merge(localData, data)) });
        localStorage.setItem("data", JSON.stringify({}));
      } catch (error) {
        console.log(error);
        return;
      }
    }
    location.pathname.includes("r/") ? navigate("/r/thankyou") : navigate("/thankyou");
};

  const handleClickPrev = () => {
    dispatch(updateState(data));
    location.pathname.includes("r/") ? navigate("/r/understandingneeds") : navigate("/understandingneeds");
  };
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const dataClone = structuredClone(data);
    document.querySelector(".active__tab5").style.background = "#ffffffa8";

    for (const key in data) {
      if (localData[key]) dataClone[key] = localData[key];
    }
    console.log(dataClone);
    setData(dataClone);
  }, []);
  return (
    <>
      {" "}
      <QuestionsPageContainer>
        <div className='sec1__question__title'>
          <div className='large__screen'>
            <img className='img-fluid' src={QuestionTitle} />
          </div>
          <div className='meduim__screen'>
            <img className='img-fluid' src={QuestionTitleMeduim} />
          </div>
          <Form>
            {" "}
            {SecFiveQuestions.map((item, index) => (
              <RadioQuestion
                error={error}
                group={item.group}
                Options={item.Options}
                key={index}
                QuestionLabelText={item.QuestionLabelText}
                num={item.num}
                questionText={item.questionText}
                value={item.value}
                handleSelect={item.handleSelect}
                data={data[item.name]}
                textArea={item.textArea}
              />
            ))}
          </Form>
        </div>
        <div className='next__prev'>
          <div className='prev__btn'>
            <img src={prevBtn} className='img-fluid' onClick={handleClickPrev} />
          </div>
          <div className='next__btn'>
            <img src={nextBtn} className='img-fluid' onClick={handleClick} />
          </div>
        </div>
      </QuestionsPageContainer>
      <Footer className='w-100' />
    </>
  );
};
