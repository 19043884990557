import styled from "styled-components";

export const FooterContainer = styled.footer`
  flex-direction: column;
  position: relative;
  bottom: 0;
`;
export const TopFooterContainer = styled.div`
  padding: 0.3em 0;
  background: -moz-linear-gradient(99.36% 50% -180deg, rgba(56, 53, 47, 1) 0%, rgba(122, 114, 106, 1) 25%, rgba(123, 114, 104, 1) 50%, rgba(178, 173, 169, 1) 75%, rgba(175, 170, 166, 1) 100%);
  background: -webkit-linear-gradient(-180deg, rgba(56, 53, 47, 1) 0%, rgba(122, 114, 106, 1) 25%, rgba(123, 114, 104, 1) 50%, rgba(178, 173, 169, 1) 75%, rgba(175, 170, 166, 1) 100%);
  background: -webkit-gradient(
    linear,
    99.36% 50%,
    0.03% 50%,
    color-stop(0, rgba(56, 53, 47, 1)),
    color-stop(0.25, rgba(122, 114, 106, 1)),
    color-stop(0.5, rgba(123, 114, 104, 1)),
    color-stop(0.75, rgba(178, 173, 169, 1)),
    color-stop(1, rgba(175, 170, 166, 1))
  );
  background: -o-linear-gradient(-180deg, rgba(56, 53, 47, 1) 0%, rgba(122, 114, 106, 1) 25%, rgba(123, 114, 104, 1) 50%, rgba(178, 173, 169, 1) 75%, rgba(175, 170, 166, 1) 100%);
  background: -ms-linear-gradient(-180deg, rgba(56, 53, 47, 1) 0%, rgba(122, 114, 106, 1) 25%, rgba(123, 114, 104, 1) 50%, rgba(178, 173, 169, 1) 75%, rgba(175, 170, 166, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#38352F', endColorstr='#AFAAA6' ,GradientType=0)";
  background: linear-gradient(270deg, rgba(56, 53, 47, 1) 0%, rgba(122, 114, 106, 1) 25%, rgba(123, 114, 104, 1) 50%, rgba(178, 173, 169, 1) 75%, rgba(175, 170, 166, 1) 100%);
  .tab {
    height: 0.75em;
    width: 0.75em;
    margin: 0 0.1em;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    border: 1px solid #ffffffa8;
    display: inline-block;
  }
  .active__tab {
    /* background-color: #ffffffa8; */
    /* background-color: ${(props) => props.bgActiveColor}; */
  }

  .logo__img {
    width: 14.1875em;
    left: 3em;
  }

  .text__img {
    width: 38.375em;
    right: 3em;
  }
  @media screen and (max-width: 1050px) {
    .logo__img {
      width: 11.1875em;
    }

    .text__img {
      width: 35.375em;
    }
  }
  @media screen and (max-width: 900px) {
    .logo__img {
      width: 9.1875em;
    }

    .text__img {
      width: 32.375em;
    }
  }
  @media screen and (max-width: 690px) {
    .logo__img {
      width: 10.1875em;
    }

    .text__img {
      width: 30.375em;
    }
  }
  @media screen and (max-width: 490px) {
    .logo__img {
      width: 8.1875em;
    }

    .text__img {
      width: 26.375em;
    }
  }
`; 
export const BottomFooterContainer = styled.div`
  background: #3f1315;
  height: 1.25em;
  width: 100%;
`; 
