import React from "react";
import { FooterContainer, TopFooterContainer, BottomFooterContainer } from "./Footer.style";
import logoFooter from "../../Assests/footerLogo.svg";
import textFooter from "../../Assests/footerText.svg";

export const Footer = () => {
  return (
    <FooterContainer className='d-flex align-items-center justify-content-center w-100 position-relative'>
      <TopFooterContainer className='w-100 d-flex align-items-center justify-content-center position-relative'>
        <div className='logo__img position-absolute'>
          <img src={logoFooter} className='img-fluid' />
        </div>
        <div className='circle__tabs'>
          <span id='tab1' className='tab active__tab'></span>
          <span id='tab2' className='tab active__tab2'></span>
          <span id='tab3' className='tab active__tab3'></span>
          <span id='tab4' className='tab active__tab4'></span>
          <span id='tab5' className='tab active__tab5'></span>
        </div>
        <div className='text__img position-absolute'>
          <img src={textFooter} className='img-fluid' />
        </div>
      </TopFooterContainer>
      <BottomFooterContainer></BottomFooterContainer>
    </FooterContainer>
  );
};
