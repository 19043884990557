import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, merge } from 'lodash';

const initialState = {
    data: {}
};

export const valueSlice = createSlice({
    name: 'formValue',
    initialState,
    reducers: {
        updateState: (state, payload) => {
            const data = cloneDeep(state.data)
            const newData = merge(data, payload.data)
            state.data = newData
        },
        resetState: () => initialState
    },
});

// Action creators are generated for each case reducer function
export const { updateState, resetState } = valueSlice.actions;

export const getFormValue = state => state.formValue.data

export default valueSlice.reducer;
