import React from "react";
import { OverViewWrapper } from "./OverView.style";
// import layerOne from "../../Assests/digitalBg.png";
import layerOne from "../../Assests/digitalBg1.png";

import layertwo from "../../Assests/digitalBgText.png";
import startBtn from "../../Assests/start-Btn.png";
import { Link } from "react-router-dom";
import { Footer } from "../Footer/Footer";
export const OverView = () => {
  return (
    <OverViewWrapper className='d-flex flex-column h-100 justify-content-center position-relative'>
      <div className='overview__container position-relative'>
        <div className='layer_one position-relative'>
          <img src={layerOne} className='img-fluid' />
        </div>
        <div className='layer_two position-absolute'>
          <img src={layertwo} className='img-fluid' />
        </div>
        <div className='position-absolute start__btn'>
          <Link to={location.pathname.includes("/r") ? "/r/technologyadvancement" : "/technologyadvancement"}>
            {" "}
            <img src={startBtn} className='img-fluid' />
          </Link>
        </div>
      </div>
    </OverViewWrapper>
  );
};
