const size = {
  screen1920: '1920px',
  screen1680: '1680px',
  screen1480: '1480px',
  screen1280: '1280px',
  screen1050: '1050px',

  screen900: '900px',
  screen800: '800px',
  screen680: '680px',
  screen428: '428px',
  screen390: '390px',
  screen340: '340px',
};
export const device = {
  screen1920: `(max-width: ${size.screen1920})`,
  screen1680: `(max-width: ${size.screen1680})`,
  screen1480: `(max-width: ${size.screen1480})`,
  screen1280: `(max-width: ${size.screen1280})`,
  screen1050: `(max-width: ${size.screen1050})`,
  
  screen900: `(max-width: ${size.screen900})`,
  screen800: `(max-width: ${size.screen800})`,
  screen680: `(max-width: ${size.screen680})`,
  screen428: `(max-width: ${size.screen428})`,
  screen390: `(max-width: ${size.screen390})`,
  screen340: `(max-width: ${size.screen340})`,
};
export default { size, device };
