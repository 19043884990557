import React, { useEffect, useState } from "react";
import { RadioQuestion } from "../RadioQuestion/RadioQuestion";
import { QuestionsPageContainer } from "./QuestionsPage.style";
import { Form } from "react-bootstrap";
import { QuestionInputs } from "../RadioQuestion/RadioQuestion.style";
import { Footer } from "../Footer/Footer";
import prevBtn from "../../Assests/prev-Btn.png";
import nextBtn from "../../Assests/next-btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFormValue, updateState } from "../../Store/valueSlice";
import { merge } from "lodash";

export const QuestionsPageThree = ({ QuestionTitle, QuestionTitleMeduim }) => {
  const [data, setData] = useState({
    security: "",
    extent_of_branding: "",
    collaborations: "",
    overall_previous: "",
    points_of_dissatisfaction: "",
    areas_for_improvements: "",
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SecThreeQuestions = [
    {
      name: "security",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest satisfaction and 1 to the lowest satisfaction,",
      num: "1",
      questionText: 'How do you rate your satisfaction with <span class="colored__word">the security and content privacy</span> on the current virtual event platforms?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group11",
      handleSelect: (e) => setData({ ...data, security: e.target.value }),
    },

    {
      name: "extent_of_branding",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest satisfaction and 1 to the lowest satisfaction,",
      num: "2",
      questionText: 'How do you rate your satisfaction with <span class="colored__word"> the extent of branding customizability</span> on the current virtual event platforms?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      handleSelect: (e) => setData({ ...data, extent_of_branding: e.target.value }),
      group: "group12",
    },

    {
      name: "collaborations",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest satisfaction and 1 to the lowest satisfaction,",
      num: "3",
      questionText: 'How do you rate your <span class="colored__word">collaborations with 3<sup>rd</sup> parties</span>, whether outsourcing or intermediaries, in virtual event management? ',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group13",
      handleSelect: (e) => setData({ ...data, collaborations: e.target.value }),
    },
    {
      name: "overall_previous",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest satisfaction and 1 to the lowest satisfaction,",
      num: "4",
      questionText: 'How do you rate <span class="colored__word">your overall previous experience</span> with the current virtual event platforms?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group14",
      handleSelect: (e) => setData({ ...data, overall_previous: e.target.value }),
    },
    {
      QuestionLabelText: "Based on your professional insights into previous virtual events in general and virtual events management through the currently available platforms,",
      num: "5",
      questionText: "How would you describe your experience according to specific points of dissatisfaction and areas for improvement?",
      name: "points_of_dissatisfaction",
      name2: "areas_for_improvements",
      textArea: "true",
      textAreaLabel1: "a) Points of Dissatisfaction",
      textArea2: "true",
      textAreaLabel2: "b) Areas for Improvements",
      handleSelect: (e) => {
        console.log(e.target.name);
        setData({ ...data, [e.target.name]: e.target.value });
      },
    },
  ];
  const handleClick = () => {
    console.log(data);
    setError(false);
    for (const key in data) {
      const element = data[key];
      if (element === "") {
        setError(true);
        return;
      }
    }

    dispatch(updateState(data));
    const localData = JSON.parse(localStorage.getItem("data"));
    localStorage.setItem("data", JSON.stringify(merge(localData, data)));
    location.pathname.includes("r/") ? navigate("/r/understandingneeds") : navigate("/understandingneeds");
  };

  const handleClickPrev = () => {
    dispatch(updateState(data));
    location.pathname.includes("r/") ? navigate("/r/eventmarketing") : navigate("/eventmarketing");
  };
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const dataClone = structuredClone(data);
    document.querySelector(".active__tab3").style.background = "#ffffffa8";

    for (const key in data) {
      if (localData[key]) dataClone[key] = localData[key];
    }
    console.log(dataClone);
    setData(dataClone);
  }, []);
  return (
    <>
      {" "}
      <QuestionsPageContainer>
        <div className='sec1__question__title'>
          <div className='large__screen'>
            <img className='img-fluid' src={QuestionTitle} />
          </div>
          <div className='meduim__screen'>
            <img className='img-fluid' src={QuestionTitleMeduim} />
          </div>
          <Form>
            {" "}
            {SecThreeQuestions.map((item, index) => (
              <RadioQuestion
                error={error}
                group={item.group}
                Options={item.Options}
                key={index}
                QuestionLabelText={item.QuestionLabelText}
                num={item.num}
                questionText={item.questionText}
                value={item.value}
                handleSelect={item.handleSelect}
                data={data[item.name]}
                data2={data[item.name2]}
                textArea={item.textArea}
                textArea2={item.textArea2}
                textAreaLabel1={item.textAreaLabel1}
                textAreaLabel2={item.textAreaLabel2}
                name={item.name}
                name2={item.name2}
              />
            ))}
          </Form>
        </div>
        <div className='next__prev'>
          <div className='prev__btn'>
            <img src={prevBtn} className='img-fluid' onClick={handleClickPrev} />
          </div>
          <div className='next__btn'>
            <img src={nextBtn} className='img-fluid' onClick={handleClick} />
          </div>
        </div>
      </QuestionsPageContainer>
      <Footer className='w-100' />
    </>
  );
};
