import styled from "styled-components";

export const TextAreaQuestionContainer = styled.div`
  textarea {
    resize: none;
    border: 1px dashed;
    padding: 0.3em;
    outline: none;
    margin: 0.4em;
    width: 106.25em;
    @media screen and (max-width: 1590px) {
      width: 92.25em;
    }
    @media screen and (max-width: 1300px) {
      width: 85.25em;
    }
    @media screen and (max-width: 1000px) {
      width: 75.25em;
    }
    @media screen and (max-width: 800px) {
      width: 70.25em;
    }
    @media screen and (max-width: 490px) {
      width: 55.25em;
    }
  }
`;