import styled from "styled-components";
import selectArrow from "../../Assests/selectArrow.png";
export const FormContainer = styled.div`
  /* border-left: 1px solid #ffffffc9; */

  .registration__form {
    .border__left {
      border-left: 1px solid #ffffff5b;
      /* padding-right: 1em; */
      .boder__space {
        padding-left: 1em;
        display: flex;
        flex-direction: column;
      }
    }
    .inputs__style,
    select {
      margin-bottom: 1em;
      background-color: #6d6e70;
      border-color: #6d6e70;
      border-radius: 1.6875em;
      color: #fff;
      /* font-size: 1.625em; */
      line-height: 1;
      height: 2.375em;
      padding-left: 0.8em;
      border: none;
      outline: none;
      :active,
      :focus {
        border: none;
        outline: none;
      }
      ::placeholder {
        color: #fff;
        padding-left: 0.8em;
      }
    }
    select {
      background-image: url(${selectArrow});
      background-position: right 0em center;
      background-size: 40px 44px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .error__massage {
      padding-left: 1.2em;
      color: red;
      font-size: 0.8em;
      font-family: "NexaRegular";
      margin: 0;
      /* padding: 0; */
      line-height: 1;
      margin-bottom: 0.3em;
      /* position: absolute; */
      margin-top: -0.4em;
    }
    .errorMassage2,
    .errorMassage3,
    .errorMassage4,
    .error__email {
      display: none;
    }
    button {
      border: none;
      background-color: transparent;
      width: max-content;
      outline: none;
      width: 12.5em;
      :active,
      :focus,
      :focus-visible {
      }
    }
  }
`;
