import React, { useEffect, useState } from "react";
import { RadioQuestion } from "../RadioQuestion/RadioQuestion";
import { QuestionsPageContainer } from "./QuestionsPage.style";
import { Form } from "react-bootstrap";
import { QuestionInputs } from "../RadioQuestion/RadioQuestion.style";
import { Footer } from "../Footer/Footer";
import prevBtn from "../../Assests/prev-Btn.png";
import nextBtn from "../../Assests/next-btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateState } from "../../Store/valueSlice";
import { merge } from "lodash";

export const QuestionsPageFour = ({ QuestionTitle, QuestionTitleMeduim }) => {
  const [data, setData] = useState({
    one_stop_shop: "",
    solution_hinder: "",
    wow_factor: "",
    speaker_friendly: "",
    communication_channel: "",
    event_platform: "",
    panel_discussion: "",
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SecFourQuestions = [
    {
      name: "one_stop_shop",
      QuestionLabelText: "In relevance to your understanding of the importance of end-to-end management in virtual events,",
      num: "1",
      questionText:
        'Do you believe that one of the major pain points lies in <span class="colored__word">the lack of a one-stop shop that provides a holistic solution with no 3<sup>rd</sup> parties intervention</span>?',
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group15",
      handleSelect: (e) => setData({ ...data, one_stop_shop: e.target.value }),
    },

    {
      name: "solution_hinder",
      QuestionLabelText: "As virtual event management imposes many risks of disconnections and lack of network stability,",
      num: "2",
      questionText: "Does the lack of a solid backup solution hinder the migration to more digital event ecosystems?",
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group16",
      handleSelect: (e) => setData({ ...data, solution_hinder: e.target.value }),
    },

    {
      name: "wow_factor",
      QuestionLabelText: "Being aware of the connection between a visually outstanding event impression and customers’ anticipation of events,",
      num: "3",
      questionText: "Do you believe that the lack of the WOW factor in virtual events is a core pain point?",
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group17",
      handleSelect: (e) => setData({ ...data, wow_factor: e.target.value }),
    },

    {
      name: "speaker_friendly",
      QuestionLabelText: "With respect to the importance of speaker engagement in virtual events platforms,",
      num: "4",
      questionText: "Would you prefer a speaker-friendly, virtual event platform sparing the hassle of technological implication, especially for tech-reluctant ones?",
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group18",
      handleSelect: (e) => setData({ ...data, speaker_friendly: e.target.value }),
    },
    {
      name: "communication_channel",
      QuestionLabelText: "Maintaining seamless, cross-stakeholder communication during virtual events could be challenging,",
      num: "5",
      questionText: "Does a private communication channel embedded on the virtual event platform contribute to seamless virtual event management?",
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group19",
      handleSelect: (e) => setData({ ...data, communication_channel: e.target.value }),
    },
    {
      name: "event_platform",

      QuestionLabelText: "Managing pre-event communication might become critical to eventual event success through optimizing value and response rate,",
      num: "6",
      questionText: "Would you prefer a virtual event platform capable of segmenting and inviting specific target customers?",
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group20",
      handleSelect: (e) => setData({ ...data, event_platform: e.target.value }),
    },
    {
      name: "panel_discussion",
      QuestionLabelText: "Based on your experience with panel discussions during virtual events,",
      num: "7",
      questionText: "Would the availability of a panel discussion mode between all speakers in front of attendees enhance customer engagement?",
      Options: [
        {
          labelInput: "Disagree",
          idInput: "Disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "Neutral",
        },
        {
          labelInput: "Agree",
          idInput: "Agree",
        },
      ],
      group: "group21",
      handleSelect: (e) => setData({ ...data, panel_discussion: e.target.value }),
    },
  ];
  const handleClick = () => {
    setError(false);
    for (const key in data) {
      const element = data[key];
      if (element === "") {
        setError(true);
        return;
      }
    }

    dispatch(updateState(data));
    const localData = JSON.parse(localStorage.getItem("data"));
    localStorage.setItem("data", JSON.stringify(merge(localData, data)));
    location.pathname.includes("r/") ? navigate("/r/plannedevents") : navigate("/plannedevents");
  };

  const handleClickPrev = () => {
    dispatch(updateState(data));
    location.pathname.includes("r/") ? navigate("/r/virtualevents") : navigate("/virtualevents");
  };
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const dataClone = structuredClone(data);
    document.querySelector(".active__tab4").style.background = "#ffffffa8";
    for (const key in data) {
      if (localData[key]) dataClone[key] = localData[key];
    }
    console.log(dataClone);
    setData(dataClone);
  }, []);
  return (
    <>
      {" "}
      <QuestionsPageContainer>
        <div className='sec1__question__title'>
          <div className='large__screen'>
            <img className='img-fluid' src={QuestionTitle} />
          </div>
          <div className='meduim__screen'>
            <img className='img-fluid' src={QuestionTitleMeduim} />
          </div>
          <Form>
            {" "}
            {SecFourQuestions.map((item, index) => (
              <RadioQuestion
                error={error}
                group={item.group}
                Options={item.Options}
                key={index}
                QuestionLabelText={item.QuestionLabelText}
                num={item.num}
                questionText={item.questionText}
                value={item.value}
                handleSelect={item.handleSelect}
                data={data[item.name]}
              />
            ))}
          </Form>
        </div>
        <div className='next__prev'>
          <div className='prev__btn'>
            <img src={prevBtn} className='img-fluid' onClick={handleClickPrev} />
          </div>
          <div className='next__btn'>
            <img src={nextBtn} className='img-fluid' onClick={handleClick} />
          </div>
        </div>
      </QuestionsPageContainer>
      <Footer className='w-100' />
    </>
  );
};
