import React, { useEffect, useState } from "react";
import { RadioQuestion } from "../RadioQuestion/RadioQuestion";
import { QuestionsPageContainer } from "./QuestionsPage.style";
import { Form } from "react-bootstrap";
import { QuestionInputs } from "../RadioQuestion/RadioQuestion.style";
import { Footer } from "../Footer/Footer";
import prevBtn from "../../Assests/prev-Btn.png";
import nextBtn from "../../Assests/next-btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFormValue, updateState } from "../../Store/valueSlice";
import { merge } from "lodash";

export const QuestionsPageTwo = ({ QuestionTitle, QuestionTitleMeduim }) => {
  const [data, setData] = useState({
    importance: "",
    effectiveness: "",
    meaningful_impressions: "",
    engagement: "",
    agree_with_post_event: "",
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const SecTwoQuestions = [
    {
      name: "importance",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the highest importance and 1 to the lowest importance,",
      num: "1",
      questionText: 'How do you rate <span class="colored__word">the importance of event marketing when it comes to shaping the brand-to-audience relation</span>?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group6",
      handleSelect: (e) => setData({ ...data, importance: e.target.value }),
    },

    {
      name: "effectiveness",
      QuestionLabelText: "On a scale from 1 to 10, where 10 refers to the most effective and 1 to the least effective,",
      num: "2",
      questionText: 'How do you rate  <span class="colored__word"> the effectiveness of event marketing versus other brand communication channels</span>?',
      Options: [
        {
          labelInput: "1",
          idInput: "1",
        },
        {
          labelInput: "2",
          idInput: "2",
        },
        {
          labelInput: "3",
          idInput: "3",
        },
        {
          labelInput: "4",
          idInput: "4",
        },
        {
          labelInput: "5",
          idInput: "5",
        },
        {
          labelInput: "6",
          idInput: "6",
        },
        {
          labelInput: "7",
          idInput: "7",
        },
        {
          labelInput: "8",
          idInput: "8",
        },
        {
          labelInput: "9",
          idInput: "9",
        },
        {
          labelInput: "10",
          idInput: "10",
        },
      ],
      group: "group7",
      handleSelect: (e) => setData({ ...data, effectiveness: e.target.value }),
    },

    {
      name: "meaningful_impressions",
      QuestionLabelText: "Based on your understanding of the role of virtual events in prompting brand loyalty,",
      num: "3",
      questionText: "Do you think that virtual events contribute to generating long-lasting, meaningful impressions for your brand?",
      Options: [
        {
          labelInput: "Strongly Disagree",
          idInput: "stronglyDisagree",
        },
        {
          labelInput: "Disagree",
          idInput: "disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "neutral",
        },
        {
          labelInput: "Agree",
          idInput: "agree",
        },
        {
          labelInput: "Strongly Agree",
          idInput: "stronglyAgree",
        },
      ],
      group: "group8",
      handleSelect: (e) => setData({ ...data, meaningful_impressions: e.target.value }),
    },

    {
      name: "engagement",
      QuestionLabelText: "In relevance to your assessment of customer engagement in virtual events,",
      num: "4",
      questionText: "Could customer engagement in virtual events be directly related to the level of customer attachment to the brand?",
      Options: [
        {
          labelInput: "Strongly Disagree",
          idInput: "stronglyDisagree",
        },
        {
          labelInput: "Disagree",
          idInput: "disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "neutral",
        },
        {
          labelInput: "Agree",
          idInput: "agree",
        },
        {
          labelInput: "Strongly Agree",
          idInput: "stronglyAgree",
        },
      ],
      handleSelect: (e) => setData({ ...data, engagement: e.target.value }),
      group: "group9",
    },

    {
      name: "agree_with_post_event",
      QuestionLabelText: "Based on the perceived relation between virtual events and branding continuity,",
      num: "5",
      questionText: '<span class="colored__word">Do you agree with post-event follow-up and branding continuity being major challenges</span> hindering the value of virtual events? ',
      Options: [
        {
          labelInput: "Strongly Disagree",
          idInput: "stronglyDisagree",
        },
        {
          labelInput: "Disagree",
          idInput: "disagree",
        },
        {
          labelInput: "Neutral",
          idInput: "neutral",
        },
        {
          labelInput: "Agree",
          idInput: "agree",
        },
        {
          labelInput: "Strongly Agree",
          idInput: "stronglyAgree",
        },
      ],
      handleSelect: (e) => setData({ ...data, agree_with_post_event: e.target.value }),

      group: "group10",
    },
  ];
  const handleClick = () => {
    setError(false);
    for (const key in data) {
      const element = data[key];
      if (element === "") {
        setError(true);
        return;
      }
    }

    dispatch(updateState(data));
    const localData = JSON.parse(localStorage.getItem("data"));
    localStorage.setItem("data", JSON.stringify(merge(localData, data)));
    location.pathname.includes("r/") ? navigate("/r/virtualevents") : navigate("/virtualevents");
  };

  const handleClickPrev = () => {
    dispatch(updateState(data));
    location.pathname.includes("r/") ? navigate("/r/technologyadvancement") : navigate("/technologyadvancement");
  };
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("data"));
    const dataClone = structuredClone(data);
    document.querySelector(".active__tab2").style.background = "#ffffffa8";
    for (const key in data) {
      if (localData[key]) dataClone[key] = localData[key];
    }
    console.log(dataClone);
    setData(dataClone);
  }, []);

  return (
    <>
      {" "}
      <QuestionsPageContainer>
        <div className='sec1__question__title'>
          <div className='large__screen'>
            <img className='img-fluid' src={QuestionTitle} />
          </div>
          <div className='meduim__screen'>
            <img className='img-fluid' src={QuestionTitleMeduim} />
          </div>
          <Form>
            {" "}
            {SecTwoQuestions.map((item, index) => (
              <RadioQuestion
                error={error}
                group={item.group}
                Options={item.Options}
                key={index}
                QuestionLabelText={item.QuestionLabelText}
                num={item.num}
                questionText={item.questionText}
                value={item.value}
                handleSelect={item.handleSelect}
                data={data[item.name]}
              />
            ))}
          </Form>
        </div>
        <div className='next__prev'>
          <div className='prev__btn'>
            <img src={prevBtn} className='img-fluid' onClick={handleClickPrev} />
          </div>
          <div className='next__btn'>
            <img src={nextBtn} className='img-fluid' onClick={handleClick} />
          </div>
        </div>
      </QuestionsPageContainer>
      <Footer className='w-100' />
    </>
  );
};
